<template>
  <div>
    <v-card-text>
      <v-container class="row1">
        <v-row>
          <v-col cols="14" sm="8" md="8">
            <v-row dense class="mb-5">
              <router-link
                v-bind:to="'/my-club/'"
                style="text-decoration: none"
              >
                <v-btn rounded color="#05a2fa">
                  <v-icon color="white"> mdi-arrow-left </v-icon>
                </v-btn>
              </router-link>
            </v-row></v-col
          >
          <v-col cols="14" sm="8" md="4">
            <v-row dense justify="end" class="mb-5">
              <div>
                <v-btn rounded color="#05a2fa" dark @click="dialog = true">
                  <v-icon color="white"> mdi-plus-circle </v-icon>
                  <v-card-text> Add a New Asset Category </v-card-text>
                </v-btn>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          style="margin-bottom: 10px; margin-top: -35px"
        >
        </v-text-field>
        <v-row>
          <v-col cols="14" sm="8" md="12" class="itemsLayout">
            <v-row dense>
              <v-col cols="2" class="pb-3">
                <v-card height="200" width="150" @click="dialog = true">
                  <v-card-text class="text-center">
                    <v-icon
                      color="#05a2fa"
                      style="font-size: 70px"
                      class="mt-11"
                    >
                      mdi-plus
                    </v-icon>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col
                v-for="card in dataFiltered"
                :key="card.assetCategoryId"
                :cols="2"
                class="pb-3"
              >
                <router-link
                  v-bind:to="{
                    path: '/assets-category/' + card.assetCategoryId,
                  }"
                  style="text-decoration: none"
                >
                  <v-card height="200" width="150" style="cursor: pointer">
                    <v-img
                      :src="`https://cdn.vuetifyjs.com/images/cards/road.jpg`"
                      class="white--text align-end"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                      width="150"
                      height="100"
                    >
                      <v-card-title style="height: 40px">
                        <div class="headerClass">
                          <v-icon
                            style="font-size: 20px; color: #05a2fa"
                            color="#ffffffff"
                            class="mb-1"
                          >
                            mdi-format-list-bulleted-type
                          </v-icon>

                          {{ card.assetCategoryName }}
                        </div>
                      </v-card-title>
                    </v-img>

                    <v-card-text>
                      <div class="headerClass">
                        <v-icon style="font-size: 20px" class="mb-1">
                          mdi-card-text
                        </v-icon>

                        {{ card.description }}
                      </div>
                    </v-card-text>
                  </v-card>
                </router-link>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="14" sm="8" md="12" style="margin-top: -25px">
            <v-pagination
              v-model="page"
              :length="pageCount"
              @click="changePage"
              circle
              color="#05a2fa"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-container>

      <v-container @click="dialogTrash = true" style="cursor: pointer">
        <v-row>
          <v-col cols="14" sm="8" md="12">
            <v-card>
              <v-card-text class="text-center" style="font-size: 15px">
                <v-icon color="#05a2fa" style="font-size: 25px">
                  mdi-delete-empty
                </v-icon>
                Trash
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Add New Category</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="categoriesForm">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.assetCategoryName"
                      label="Category Name"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.description"
                      label="Category Description"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="validate"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogTrash" max-width="1200">
        <v-card class="pa-10">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          >
          </v-text-field>

          <v-data-table :headers="headers" :items="trashed" :search="search">
            <template v-slot:[`item.restore`]="{ item }">
              <v-icon color="#05a2fa" @click="restoreItem(item)">
                mdi-restore
              </v-icon>
            </template>

            <template v-slot:[`item.delete`]="{ item }">
              <v-icon color="#FF0000" @click="permanentDelete(item)">
                mdi-trash-can-outline
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogRestore" max-width="500px">
        <v-card>
          <v-card-title class="headline">
            Are you sure you want to restore this item?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="dialogRestore = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="restoreItemConfm">
              OK
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogPermanentDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline">
            Are you sure you want to delete this item permanently?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">
              OK
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </div>
</template>

<style>
.row1 {
  margin-top: -25px;
}
.itemsLayout {
  height: 450px;
  overflow: hidden;
  overflow-y: auto;
}
.addbutton {
  float: right;
}
.temp {
  background-color: rgb(238, 238, 238);
}
</style>
<script>
import MaterialCard from "../components/MaterialCard.vue";

export default {
  components: { MaterialCard },
  data() {
    return {
      dialogTrash: false,
      dialog: false,
      dialogDelete: false,
      dialogRestore: false,
      dateModal: false,
      dialogPermanentDelete: false,
      search: "",

      headers: [
        { text: "Categogry Id", value: "assetCategoryId" },
        { text: "Categogry Name", value: "assetCategoryName" },

        { text: "Category Name", value: "assetCategoryName" },
        { text: "Description", value: "description" },
        { text: "", value: "restore" },
        { text: "", value: "delete" },
      ],
      categories: [],

      editedIndex: -1,
      editedItem: {
        assetCategoryName: "",
        description: "",
      },
      defaultItem: {
        assetCategoryName: "",
        description: "",
      },
      trashed: [],
      page: 1,
      pageCount: 10,
      start: 0,
      end: 11,
      dataFiltered: [],

      filtered: {
        orderBy: "",
        orderDirection: "desc",
        skip: 0,
        take: 11,
        searchValue: "",
        searchColumn: "",
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add a New Product" : "Edit Product";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    page: function () {
      this.changePage();
    },
  },

  created() {
    this.getDataFromApi();
    this.getFilteredData();
  },

  methods: {
    getDataFromApi() {
      var clubId = 1;
      this.$http.get("/AssetCategory").then((response) => {
        this.categories = response.data;
        this.changePage();
      });

      this.$http.get(`/AssetCategory/GetAllTrashed`).then((response) => {
        this.trashed = response.data.data;
      });
    },
    getFilteredData() {
      this.$http
        .post("/AssetCategory/GetAllByFilter", this.filtered)
        .then((response) => {
          this.dataFiltered = response.data.data;
        });
    },

    updateData() {
      this.page = 1;
      this.changePage();
    },
    changePage() {
      // var diff = this.page - 1;
      // this.filtered.skip = this.start + this.end * diff;
      // this.getFilteredData();

      var diff = this.page - 1;
      var newStart = this.start + this.end * diff;
      var newEnd = this.end + this.end * diff;

      const items = this.categories.slice(newStart, newEnd);
      this.dataFiltered = items;
    },
    editItem(item) {
      //this.editedIndex = this.users.indexOf(item);
      this.editedIndex = 0;
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      // this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    restoreItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogRestore = true;
    },
    permanentDelete(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogPermanentDelete = true;
    },
    restoreItemConfm() {
      var Id = this.editedItem.assetCategoryId;
      this.$http
        .post("/AssetCategory/Restore?Id=" + Id)
        .then((response) => {
          if (response.data.success === true) {
            this.getDataFromApi();

            // this.trashed.shift(response.data.data);
            // this.categories.unshift(response.data.data);

            this.updateData();

            this.$toast.success("Restore successfully.", "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.$toast.error(" Restore Process Failed", "Error", {
              position: "topRight",
            });
          }
        });

      this.dialogRestore = false;
    },
    deleteItemConfirm() {
      var Id = this.editedItem.assetCategoryId;
      this.$http
        .delete("/AssetCategory/" + Id)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success(
              "Asset Category deleted successfully.",
              "Success",
              {
                position: "topRight",
              }
            );
            this.trashed.shift(response.data.data);
          }
        })

        .catch((error) => {
          if (error.response.status === 404) {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        });
      //this.users.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      console.log(this.categories);
    },

    closeDelete() {
      this.dialogDelete = false;
      this.dialogPermanentDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    validate() {
      if (this.editedItem.assetCategoryName && this.editedItem.description) {
        this.save();
      } else {
        this.$toast.error("Please Fill the Form", "Error", {
          position: "topRight",
        });
      }
    },

    save() {
      this.$http
        .post("/AssetCategory", this.editedItem)
        .then((response) => {
          if (response.data.isSuccess === true) {
            this.categories.unshift(response.data.data);
            this.updateData();
            this.$toast.success(
              "Asset Category added successfully.",
              "Success",
              {
                position: "topRight",
              }
            );
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.$toast.error("Adding Process Failed", "Error", {
              position: "topRight",
            });
          }
        });

      this.close();
    },
    getStatusColor(data) {
      switch (data) {
        case "true":
          return "success";
        case "false":
          return "error";
        default:
          return "";
      }
    },
  },
};
</script>
